import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class TransportersService {
    
    constructor(
        private apiService: ApiService
    ) { }

    public getEkont() {
        return this.apiService.get('ekont');
    }
}
