import { CoreModule } from './core';

import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SplashScreenComponent } from './splash/splash-screen.component';
import { ApplicationComponent } from './application.component';
import { ApplicationRouter } from './application.routing';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
	declarations: [
		ApplicationComponent,
		SplashScreenComponent
	],
	imports: [
		BrowserModule.withServerTransition({
			appId: 'ng-universal-demystified'
		}),
		ApplicationRouter,
		BrowserModule,
		CommonModule,
		NgOptimizedImage,
		CoreModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately', scope: '/' }),
	],
	bootstrap: [
		ApplicationComponent,
		SplashScreenComponent
	]
})

export class AppServerModule {
	// constructor(
	//   private translate: TranslateService,
  
	// ) {
	//   this.translate.setDefaultLang('en');
	//   translate.use('en');
	// }
}