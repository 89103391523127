import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root'})
export class AuthenticationInterceptor implements HttpInterceptor {
	token = null;
	constructor(
		authService: AuthService,
	) {
		const userSession = authService.currentUserValue ? JSON.parse(authService.currentUserValue) : '';
		this.token = userSession && userSession.sessionId;
	}

	intercept(
		request: HttpRequest<any>, 
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		let token = this.token;
		try {
			token = JSON.parse(localStorage.getItem('jilanov-user')).sessionId;
		} catch(e) {
			console.log('Could not parse');
		}
		if (token) {
			const cloned = request.clone({
				headers: request.headers.set('Authorization', token)
			});

			return next.handle(cloned);
		} else {
			return next.handle(request);
		}
	}
}