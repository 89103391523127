import { Injectable, EventEmitter } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class FiltersService {

    filters = [];
    
    constructor(
        private apiService: ApiService
    ) { }

    public async getFilters() {
        if(!this.filters.length) {
            await this.getAllFilters();
            return this.filters;
        } else {
            return this.filters;
        }
    }

    public async getAllFilters() {
        return this.apiService.get('filters/cached').then((data: any) => {
            this.filters = data;
        });
    }
}
