import { Injectable, EventEmitter } from '@angular/core';
import { ApiService } from './api.service';
import { TranslateHelperService } from './translate-helper.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ProductsService {

    categories = [];

    cache = {};
    
    constructor(
        private http: HttpClient,
		private translate: TranslateHelperService,
        private apiService: ApiService
    ) { }

    public async getByCategory(category) {
        let query = category;
        if(this.cache[query]) {
            return this.cache[query];
        }
        let res = await this.apiService.get('products/product/cached/' + query);
        this.cache[query] = res;
        return res;
    }

    public getByUrl(url) {
        return this.apiService.get('products/product?url=' + url);
    }

    public getByFilter() {
        return this.apiService.get('products');
    }

    public getByListOfIds(list) {
        return this.apiService.get('products?ids=' + JSON.stringify(list));
    }

    public async getContent(status) {
		switch(status) {
			case 'A':
				return await this.translate.getTranslation('products:status-a');
			case 'A-':
				return await this.translate.getTranslation('products:status-a-');
			case 'B':
				return await this.translate.getTranslation('products:status-b');
			case 'C':
				return await this.translate.getTranslation('products:status-c');
		}
	}

    public getItemsByText(text) {
        return this.apiService.get('products-by-text/' + text);
    }

    public getLeasePlanDSK(id: string, price: number) {
        return this.apiService.get('dsk/getCalculationForAllSchemes/' + id + '/' + price);
    }

    public getLeasePlanTBI(id: string, price: number) {
        return this.apiService.get('tbi/getCalculationForAllSchemes/' + id + '/' + price);
    }
}
