<div class="top">
	<header *ngIf="!inMaintenance" class="Header">
		<div *ngIf="showBack" (click)="back()">
			<img ngSrc="./assets/images/Back.svg" alt=" - " draggable="false" class="Header__Search" width="60" height="60">
		</div>
		<a routerLink="/">
			<img ngSrc="./assets/images/ApplicationComponent/Logo.svg" alt=" - " draggable="false" class="Header__Logo" width="60" height="60">
		</a>

		<!-- <ng-container *ngIf="!isLogged; else LOGGED">
			<a routerLink="/log-in">
				<img ngSrc="./assets/images/ApplicationComponent/User Not Logged.svg" alt=" - " draggable="false" class="Header__User">
			</a>
		</ng-container> -->
	<!-- 
		<ng-template #LOGGED>
			<a routerLink="#">
				<img ngSrc="./assets/images/ApplicationComponent/User Logged.svg" alt=" - " draggable="false" class="Header__User">
			</a>
		</ng-template> -->

		<div>
			<a 
				routerLink="/cart" 
				class="Cart-Container"
				data-toggle="popover" 
				data-trigger="hover"
				data-placement="bottom"
				data-html="true"
				title="Koличка" 
				data-content="Вижте наличните предмети в количката си"
			>
				<img ngSrc="./assets/images/ApplicationComponent/Cart.svg" alt=" - " draggable="false" class="Header__Cart" width="60" height="60">
				<span *ngIf="itemsInCart" class="Header__Cart__Indicator">{{ itemsInCart }}</span>
			</a>
		</div>

		

		<!-- <a *ngIf="isImportant" data-target="#IMPORTANT" data-toggle="modal">
			<img ngSrc="./assets/images/ApplicationComponent/Important.svg" alt=" - " draggable="false" class="Header__Important">
		</a> -->

		<div *ngIf="isImportant" id="IMPORTANT" class="Header__Modal modal fade">

			<article class="modal-dialog modal-dialog-centered">
				<section class="modal-content">
					<aside class="Modal-Header modal-header">
						<img ngSrc="./assets/images/ApplicationComponent/Important.svg" alt=" - " draggable="false" width="60" height="60">
						<h4 class="Modal-Title modal-title ">{{ importantMessageTitle }}</h4>
					</aside>
					<p class="Modal-Body modal-body text-center">{{ importantMessage }}</p>
				</section>
			</article>

		</div>

		<img (click)="switchLanguage()" [ngSrc]="currentLanguage" alt=" - " draggable="false" class="Header__Language" width="60" height="60">

		<div (click)="searchDialog()">
			<img ngSrc="./assets/images/Search2.svg" alt=" - " draggable="false" class="Header__Search" width="60" height="60">
		</div>

		<div *ngIf="!isLogged">
			<img 
				src="./assets/images/login.svg" 
				routerLink="/log-in"
				alt=" - " draggable="false" class="Header__Language"
				data-toggle="popover" 
				data-trigger="hover"
				data-placement="bottom"
				data-html="true"
				title="Вход" 
				data-content="Влезте в профила си"
				width="60" height="60"
			/>
		</div>
		
		<div *ngIf="isLogged" class="dropdown">
			<img ngSrc="./assets/images/profile.svg" alt=" - " draggable="false" class="Header__Language dropbtn" width="60" height="60">
			<div class="dropdown-content">
				<span routerLink="/profile">{{ 'global:profile' | translate }}</span>
				<span (click)="logout()">{{ 'global:logout' | translate }}</span>
			</div>
		</div>
	</header>



	<article *ngIf="!inMaintenance" class="Information">
		<a routerLink="/contacts" class="Information__Location">
			<img ngSrc="./assets/images/ApplicationComponent/Location.svg" alt=" - " draggable="false"  width="60" height="60">
			<span>{{ 'global:visit-us' | translate }}</span>
		</a>
		<div class="break"></div>

		<a [href]="contact" class="Information__Contact">
			<img ngSrc="./assets/images/ApplicationComponent/Contact.svg" alt=" - " draggable="false"  width="60" height="60">
			<span>{{ 'global:call-us' | translate }}</span>
		</a>
		<div class="break"></div>

		<a routerLink="/content-policy" class="Information__Content-Policy">
			<img ngSrc="./assets/images/ApplicationComponent/Content Policy.svg" alt=" - " draggable="false"  width="60" height="60">
			<span>{{ 'global:content-policy' | translate }}</span>
		</a>
		<div class="break"></div>

		<a routerLink="/classes" class="Information__Classes">
			<img ngSrc="./assets/images/ApplicationComponent/Classes.svg" alt=" - " draggable="false"  width="60" height="60">
			<span>{{ 'global:quality' | translate }}</span>
		</a>
	</article>


	<div class="navigation-container">
		<nav *ngIf="!inMaintenance" class="Navigation test">
			<!-- test -->
			<a *ngFor="let navigationItem of navigation" (click)="navigate(navigationItem.route,navigationItem.label[getLanguage])" [class]="navigationItem.class" [href]="navigationItem.route">
				<img [ngSrc]="navigationItem.icon" alt=" - " draggable="false" 
					data-toggle="popover" 
					data-trigger="hover"
					data-placement="top"
					data-html="true"
					[title]="navigationItem.label[getLanguage]" 
					[attr.data-content]="navigationItem.content[getLanguage]"
					(mouseover)='setFocus(navigationItem)'
					width="60" height="60"
				>
				<span class="category-label">{{navigationItem.label[getLanguage]}}</span>
			</a>
		</nav>
		<div *ngIf="!mobile" class="Accordion-Container">
			<article *ngFor="let category of lastFocusedSubCategories" class="Accordion accordion" (click)="navigateSubcategory(category)">
				<section class="Card card">
					<aside [id]="category.heading" class="Card-Header card-header">
						<button class="btn collapsed">{{ category.label[getLanguage] }}</button>
					</aside>
				</section>
			</article>
		</div>
	</div>
</div>
<div class="router-container">
	<app-splash-screen></app-splash-screen>
	<router-outlet></router-outlet>
</div>

<footer *ngIf="!inMaintenance" class="Footer">
	<a [href]="contact" class="Contact">
		<img ngSrc="./assets/images/ApplicationComponent/Contact.svg" alt=" - " draggable="false" width="60" height="60" />
		<span>{{ 'global:call-us' | translate }}</span>
	</a>

	<aside class="Icons">
		<span *ngIf="!mobile">{{ 'global:follow-us' | translate }}</span>
		<a rel="noreferrer" *ngFor="let footerItem of footer" [href]="footerItem.link" target="_blank">
			<img [ngSrc]="footerItem.icon" alt=" - " draggable="false" width="60" height="60">
		</a>
	</aside>
</footer>