import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthenticateGuard implements CanActivate {

	constructor(
		private _routerService: Router,
		private authService: AuthService
	) { }

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		// if (this.authService.isLoggedIn()) {
			return true;
		// } else {
		// 	return this._routerService.navigate(['/'], { queryParams: { ...next.params, ...next.queryParams } });
		// }
	}
}
