import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// const baseUrl = 'http://localhost:3200/api/'
const baseUrl = 'https://www.jilanov.com/api/'

@Injectable()

export class ApiService {
    
    constructor(
        private http: HttpClient
    ) {
        // eventBusService.categoriesUpdate.subscribe((eventData) => this.setCategories(eventData.categories));
    }

    public getBaseUrl() {
        return baseUrl.split('api')[0];
    }

    public get(url) {
        const httpOptions = {
            headers: new HttpHeaders({
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Content-Type':  'application/json'
            })
        }
        return this.http.get(baseUrl + url, httpOptions).toPromise();
    }

    public post(url, body, options = {}) {
        return this.http.post(baseUrl + url, body, options).toPromise();
    }

    public patch(url, body) {
        return this.http.patch(baseUrl + url, body).toPromise();
    }

    public delete(url) {
        return this.http.delete(baseUrl + url).toPromise();
    }
}
