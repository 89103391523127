import { Injectable, EventEmitter } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class CategoriesService {

    categories = [];
    subCategories = [];
    scrollPosition = 0;
    
    constructor(
        private apiService: ApiService
    ) { }

    public async getCategories() {
        if(!this.categories.length) {
            await this.getAllCategories();
            return this.categories;
        } else {
            return this.categories;
        }
    }

    public async getSubCategories() {
        if(!this.subCategories.length) {
            await this.getAllSubcategories();
            return this.subCategories;
        } else {
            return this.subCategories;
        }
    }

    public getAllCategories() {
        return this.apiService.get('categories/cache').then((data: any) => {
            this.categories = data;
        });
    }

    public getAllSubcategories() {
        return this.apiService.get('sub-categories/cache').then((data: any) => {
            this.subCategories = data;
        });
    }

    public saveScrollPosition(y) {
        this.scrollPosition = y;
    }
}
