import { AfterViewInit, Component, OnInit, ElementRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { TranslateHelperService } from './core/services/translate-helper.service';
import { LANGUAGE_CODES, DEFAULT_LANG_CODE } from './models/language';
import { CartService } from './core/services/cart.service';
import { CategoriesService } from './core/services/categories.service';
import { AuthService } from './core/services/auth.service';
import Swal from 'sweetalert2'

import categoriesData from './initial/categories.json';
import { filter, map } from 'rxjs/operators';

declare var $: any;
window['$'] = window['$'] || null;

@Component({
	selector: 'app-root',
	templateUrl: './application.component.html',
	styleUrls: ['./application.component.scss']
})

export class ApplicationComponent implements OnInit, AfterViewInit {
	mobile = window.innerHeight + window.innerWidth < 2000;
	navigation: any = categoriesData;
	lastFocusedSubCategories = [];
	categories = categoriesData;
	subcategories = [];
	once = false;
	showBack = false;
	private sub = this._routerService.events
		.pipe(
			filter(event => event instanceof NavigationStart),
			map(event => event as NavigationStart),  // appease typescript
		)
		.subscribe(
			event => {
				this.showBack = event.url.includes('product');
			}
		);

	constructor(
		@Inject(DOCUMENT) private document,
		private elementRef: ElementRef,
		private _routerService: Router, 
		private authService: AuthService,
		private translate: TranslateHelperService,
		private translateHelper: TranslateHelperService,
		private cartService: CartService,
		private categoriesService: CategoriesService
	) {
		translate.addLangs(LANGUAGE_CODES);
		translate.setDefaultLang(DEFAULT_LANG_CODE);
		this.translate.use(this.translateHelper.getLanguageCode());

		// once the page is initialized, fetch the real data
		Promise.all(Array.from(document.images).filter((img: any) => !img.complete).map((img: any) => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
			if(!this.once) {
				this.once = true;
				setTimeout(() => {
					this.getCategories();
					this.getSubCategories();
				}, 100)
			}
		});

		this.authService.currentUser.subscribe((data) => {
			if(data) {
				this.isLogged = true;
			}
		})

	}

	ngAfterViewInit() {
		console.log('DOM READY, append scripts');
		// tags
		var s = document.createElement('script');
		s.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=UA-50355706-1' );
		this.elementRef.nativeElement.appendChild( s );

		// data layer
		var s = document.createElement('script');
		s.type = 'text/javascript';
		var code = `
			(function() {
				if(!window) return;
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());

				gtag('config', 'UA-50355706-1');
			})()
		`;
		s.appendChild(document.createTextNode(code));
		this.elementRef.nativeElement.appendChild(s);

		// maps
		var s = document.createElement('script');
		s.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDJ7JbwKivO6_50DDkipCoxkgtcURyelr8&libraries=places' );
		this.elementRef.nativeElement.appendChild( s );

		// jquery
		var s = document.createElement('script');
		s.setAttribute('src', 'https://code.jquery.com/jquery-3.6.0.min.js' );
		s.setAttribute('crossorigin', 'anonymous' );
		s.onload = () => {
			// popovers
			var s = document.createElement('script');
			s.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.11.0/umd/popper.min.js' );
			s.setAttribute('crossorigin', 'anonymous' );
			s.onload = () => {
			// bootstrap
				var s = document.createElement('script');
				s.setAttribute('src', 'https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js' );
				s.setAttribute('integrity', 'sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6' );
				s.setAttribute('crossorigin', 'anonymous' );
				this.elementRef.nativeElement.appendChild( s );
			};
			this.elementRef.nativeElement.appendChild( s );
		};
		this.elementRef.nativeElement.appendChild( s );
	}

	back() {
		window.history.go(-1)
	}

	searchDialog() {
		Swal.fire({
			title: 'Напишете ключова дума',
			input: 'text',
			inputAttributes: {
			  autocapitalize: 'off'
			},
			showCancelButton: true,
			cancelButtonText: 'Откажи',
			confirmButtonText: 'Търси',
			showLoaderOnConfirm: true,
			preConfirm: (text) => {
				if(!text.length) {
					Swal.showValidationMessage(
						`Моля въведете ключова дума`
					)
					return;
				}
			}
			}).then((result) => {
				if (result.isConfirmed) {
					this._routerService.navigate(['search', result.value]);
				}
			})
	}

	setFocus(item) {
		this.lastFocusedSubCategories = [];
		if(item.showNewAndOldFilter) {
			this.lastFocusedSubCategories.push({
				type: 'filter',
				isNew: true,
				showOnNav: true,
				custom: true,
				route: item.route,
				label: {
					bg: 'Нови',
					en: 'New'
				},
				heading: 'New'
			});
			this.lastFocusedSubCategories.push({
				type: 'filter',
				isNew: false,
				showOnNav: true,
				custom: true,
				route: item.route,
				label: {
					bg: 'Употребявани',
					en: 'Used'
				},
				heading: 'Used'
			});
		}
		this.lastFocusedSubCategories.push(...(this.subcategories.filter((e) => e.category === item.products && item.showOnNav)));
	}

	logout() {
		this.authService.logout();
		this.isLogged = false;
	}

	ngOnInit() {
		this.cartService.itemsChange.subscribe((data) => this.itemsInCart = data.length);
		
		// Swal.fire(
		// 	'',
		// 	'Сайтът е в процес на обновяване. Свържете се с нас по телефона или чрез нашия OLX магазин',
		// 	'warning'
		// );
		if(!this.mobile) {
			let interval = setInterval(() => {
				// Init popovers
				console.log('try');
				if(!window['$']) {
					return;
				}
				console.log('try2');
				if(!$('[data-toggle="popover"]').popover) {
					return;
				}
				clearInterval(interval);
				console.log('done');
				$('[data-toggle="popover"]').popover()
			}, 1000)
		}
	}

	async getCategories() {
		this.categories = (await this.categoriesService.getCategories() as any).filter((el) => el.showOnNav);
		this.navigation = this.categories.sort((a, b) => a.index - b.index);
	}

	async getSubCategories() {
		this.subcategories = (await this.categoriesService.getSubCategories() as any).filter((el) => el.showOnNav);
	}
	

	switchLanguage(): void {
		if (this.changedLanguage) {
			this.currentLanguage = this.language.bg;
			this.changedLanguage = !this.changedLanguage;
			this.translate.use('bg');
		}
		else {
			this.currentLanguage = this.language.en;
			this.changedLanguage = !this.changedLanguage;
			this.translate.use('en');
		}
	}

	navigate(location, title) {
		this._routerService.navigate([location], { state: { title: title }});
		return false;
	}

	navigateSubcategory(subcategory) {
		if(subcategory.custom) {
			this._routerService.navigate(
				[subcategory.route], 
				{
				  queryParams: {
						sortBy: 'isNew',
						id: subcategory.isNew
				  }
				}
			);
			return;
		}
		let category = this.categories.filter((el) => el.products === subcategory.category)[0];
		this._routerService.navigate(
			[category.route], 
			{
			  queryParams: {
					subCategory: subcategory.url,
			  }
			}
		);
		this.lastFocusedSubCategories = [];
	}

	get getLanguage() {
		return this.translateHelper.getLanguageCode()
	}

	// COMPONENT DATA
	footer: IFooter[] = [
		{
			link: 'https://www.facebook.com',
			icon: './assets/images/ApplicationComponent/Facebook.svg'
		},
		{
			link: 'https://www.youtube.com',
			icon: './assets/images/ApplicationComponent/Youtube.svg'
		}
	];

	language: ILanguage = {
		bg: './assets/images/ApplicationComponent/BG.svg',
		en: './assets/images/ApplicationComponent/UK.svg'
	};

	changedLanguage: boolean = false;
    contact: string = 'tel: +359 888 283 711';
    currentLanguage: string = this.language.bg;
	importantMessage: string = 'Сайта е в процес на разработка';
	importantMessageTitle: string = 'Версия - отворена бета';
    inMaintenance: boolean = false;
    isImportant: boolean = false;
    isLogged: boolean = false;
    itemsInCart: number = 0;
}



// COMPONENT INTERFACES
interface IFooter {
	link: string,
	icon: string
}

interface ILanguage {
	bg: string,
	en: string
}

interface INavigation {
	label: string,
	route: string,
	class: string,
	icon: string,
	title: string,
	content: string
}