import { animate, animateChild, query, style, transition, trigger } from "@angular/animations";
import { ApplicationRef, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { PwaService } from "./pwa.service";

// <div class="splash-screen" *ngIf="show" @fadeOut>

@Component({
    selector: 'app-splash-screen',
    template: `
        <div class="splash-screen" *ngIf="show" @fadeOut>
            <picture>
                <source srcset="./assets/logo-trans.webp" type="image/webp">
                <source srcset="./assets/logo-trans.png" type="image/png"> 
                <img height="50%" alt="splash" src="./assets/logo-trans.webp" draggable="false">
            </picture>
        </div>
    `,
    animations: [
        // the fade-in/fade-out animation.
        trigger('fadeOut', [
            transition(':leave', [
                query(':leave', animateChild(), {optional: true}),
                animate(300, style({opacity: 0}))
            ]),
        ]),
    ],
    styles: [`
        .splash-screen {
            display: flex;
            justify-content: center;
            background: rgb(200,200,200);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 9999;
        }

        picture {
            height: 100%;
        }

        img {
            height: 50%;
            margin: auto;
            transform: translate(0, 50%);
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplashScreenComponent implements OnInit {
    show = true;
	mobile = window.innerHeight + window.innerWidth < 2000;

    constructor(
        private pwaService: PwaService,
        private cdr: ChangeDetectorRef,
        private appRef: ApplicationRef,
    ) {
        setTimeout(() => {
            this.show = false;
            this.cdr.detectChanges();
        }, this.mobile ? 500 : 100)
    }

    ngOnInit() {
        this.pwaService.checkForUpdate()
            .subscribe(result => {
                if(this.show) {
                    this.show = result;
                    this.cdr.detectChanges();
                }
            });
    }
}