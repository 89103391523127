import { RouterModule, Routes } from '@angular/router';



const ApplicationRoutes: Routes = [
	{
		path: '',
		loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
	},
	{
		path: 'cart',
		loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule)
	},
	{
		path: 'search/:text',
		loadChildren: () => import('./pages/search/search.module').then(m => m.SearchModule)
	},
	{
		path: 'category/:categoryId',
		loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryModule)
	},
	{
		path: 'category/full-page/software-development',
		loadChildren: () => import('./pages/software-development/software-development.module').then(m => m.SoftwareDevelopmentModule)
	},
	{
		path: 'category/:categoryId:/product/:productId',
		loadChildren: () => import('./pages/product/product.module').then(m => m.ProductModule)
	},
	{
		path: 'contacts',
		loadChildren: () => import('./pages/contacts/contacts.module').then(m => m.ContactsModule)
	},
	{
		path: 'content-policy',
		loadChildren: () => import('./pages/content-policy/content-policy.module').then(m => m.ContentPolicyModule)
	},
	{
		path: 'log-in',
		loadChildren: () => import('./pages/log-in/log-in.module').then(m => m.LoginModule)
	},
	{
		path: 'category/full-page/maintenance',
		loadChildren: () => import('./pages/service/service.module').then(m => m.ServiceModule)
	},
	{
		path: 'orders',
		loadChildren: () => import('./pages/order/order.module').then(m => m.OrderModule)
	},
	{
		path: 'tbi-order',
		loadChildren: () => import('./pages/tbi-order/tbi-order.module').then(m => m.TBIOrderModule)
	},
	{
		path: 'dsk-order',
		loadChildren: () => import('./pages/dsk-order/dsk-order.module').then(m => m.DSKOrderModule)
	},
	{
		path: 'profile',
		loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule)
	},
	{
		path: 'classes',
		loadChildren: () => import('./pages/classes/classes.module').then(m => m.ClassesModule)
	},
	{
		path: 'eu',
		loadChildren: () => import('./pages/eu/eu.module').then(m => m.EUModule)
	},
	{
		path: '**',
		loadChildren: () => import('./pages/error-page/error-page.module').then(m => m.ErrorPageModule)
	}
];



export const ApplicationRouter = RouterModule.forRoot(ApplicationRoutes, { scrollPositionRestoration: 'top' });