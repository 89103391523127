import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class PromosService {

    promos = [];
    
    constructor(
        private apiService: ApiService
    ) { }

    public async validate(promo) {
        return await this.apiService.get(`promos/${promo}`);
    }
}
